import { useEffect, useState } from 'react'

export const isBrowser = () => typeof window !== 'undefined'

export const isEDMode = isBrowser() && window.location.href.indexOf('ed=1') > -1

// https://dev.to/reedbarger/how-to-create-a-usewindowsize-react-hook-2bcm
export const useWindowSize = () => {
  const isSSR = typeof window === 'undefined'
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  })

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}

export const scrollLockEnableNav = () => {
  const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
  const body = document.body
  body.style.position = 'fixed'
  body.style.top = `-${scrollY}`
}

export const scrollLockDisableNav = screenPos => {
  const body = document.body
  const scrollY = body.style.top
  body.style.position = ''
  body.style.top = ''
  window.scrollTo(0, parseInt(scrollY || '0') * -1)
}

export const scrollLockEnable = setScrollPosition => {
  const body = document.querySelector('body')
  const offset = window.pageYOffset
  setScrollPosition(offset)
  body.style.overflow = 'hidden'
  body.style.position = 'fixed'
  body.style.top = `-${offset}px`
  body.style.width = '100%'
}

export const scrollLockDisable = scrollPosition => {
  const body = document.querySelector('body')
  body.style.removeProperty('overflow')
  body.style.removeProperty('position')
  body.style.removeProperty('top')
  body.style.removeProperty('width')
  if (scrollPosition != 0) {
    window.scrollTo({
      behavior: 'auto',
      top: scrollPosition,
    })
  }
}

export const isTouchEnabled = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  )
}

export const getApiEndpoint = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    if (
      window.location.hostname.indexOf('inpefahcp.com') > -1 ||
      window.location.hostname.indexOf('.klickcloud.net') > -1
    ) {
      return '/api/'
    } else {
      return 'https://inpefahcp-stage.klickcloud.net/api/'
    }
  }
}

export const scrollToContent = () => {
  // scroll to # id if it exists in url
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const url = window.location.href
    const hash = url.substring(url.indexOf('#') + 1)
    if (hash) {
      const el = document.getElementById(hash)
      if (el) {
        el.scrollIntoView()
      }
    }
  }
}

// add anchor hash to url
export const showHashUrl = e => {
  const href = e.target.getAttribute('href')
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    window.history.pushState(null, null, href)
  }
}